<template>
  <el-scrollbar class="scrollbar" style="height: calc(100%);background:#F1F1F1;">
    <div style="padding: 10px;">
      <div class="row" style="flex-wrap: wrap;">
        <div
          v-for="(v) in avCollectList"
          :key="v.md5"
          class="column video"
          @click="onVideoClick(v.id,v.groupingId,v.tag)"
        >
          <el-image
            v-if="v.url.includes('mp3')"
            fit="cover"
            :src="require('@/assets/mp3-logo.jpg')"
            lazy
          />
          <el-image v-else class="img" fit="cover" :src="v.cover" lazy />
          <div class="column info">
            <label
              class="grouping"
            >专栏：{{ v.pGrouping==null? `${v.grouping}`:`${v.pGrouping} - ${v.grouping}` }}</label>
            <label class="name">{{ v.name }}</label>
            <label class="lastTime">{{ secondToTimeStr(parseInt(v.lastTime / 1000)) }}</label>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>
<script>
import { secondToTimeStr } from '@/utils'
import { avCollectList } from '@/apis/video'
export default {
  name: 'AvCollect',
  components: {},
  props: {},
  data() {
    return {
      avCollectList: []
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    avCollectList({ page: { index: 1, rows: 1000 } }).then(res => {
      this.avCollectList = res.data
      console.log(res)
    })
  },
  methods: {
    secondToTimeStr,
    // 视频点击事件
    onVideoClick(id, grouping, tag) {
      this.$router.push({
        path: '/player',
        query: {
          g: grouping,
          v: id,
          t: tag,
          dir: `AVCOLLECT`
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.scrollbar {
  margin-bottom: 50px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
.video {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  height: 150px;
  width: 250px;
  background: rgb(49 49 49);
  margin: 10px;
  .img {
    height: 150px;
  }
  .finallyTime {
    position: absolute;
    left: 0px;
    top: 10px;
    background: #c61200;
    color: white;
    font-size: 12px;
    padding: 0px 5px;
    border-radius: 0px 6px 6px 0px;
  }
  .info {
    position: absolute;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.771);
    width: 100%;
    .grouping {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: rgb(194, 194, 194);
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: white;
      margin-left: 5px;
      margin-bottom: 5px;
    }
    .lastTime {
      position: absolute;
      right: 5px;
      top: 5px;
      color: rgb(141, 141, 141);
      font-size: 12px;
    }
  }
}
</style>
